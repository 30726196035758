/* eslint react/forbid-prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import Banner from '../Banner';
import CallToAction from '../../core/CallToAction';
import ExternalLink from '../../../elements/Link/ExternalLink';
import DarkButton from '../../../elements/DarkButton';
import TabLink from '../../../elements/Link/TabLink';

const ShopBanner = ({ bookImagePath, className = '', ...props }) => (
  <div className={`${styles.shopBanner} ${className}`} {...props}>
    <Banner>
      <div className={styles.container}>
        <div className={styles.text}>
          <p>
            <span>
              The British Museum has created, developed and sourced a wide range of publications and
              products inspired by The Albukhary Gallery of the Islamic World. Highlights of this
              collection include the two publications
            </span>
            <i> &#39;The Islamic world: A history in objects&#39;</i>
            <span> and </span>

            <i>&#39;The making of the Albukhary Foundation Gallery of the Islamic world.&#39;</i>
          </p>
          <ExternalLink
            as={TabLink}
            className={styles.callToAction}
            href="https://www.britishmuseumshoponline.org/inspired-by/inspired-by-the-gallery-of-the-islamic-world.html"
          >
            <CallToAction as={DarkButton} text="Visit the Shop" />
          </ExternalLink>
        </div>
        <img className={styles.image} alt="Book" src={bookImagePath} />
      </div>
    </Banner>
  </div>
);

ShopBanner.defaultProps = {
  className: '',
};

ShopBanner.propTypes = {
  bookImagePath: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ShopBanner;
