import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Hero = ({ className, ...props }) => (
  <div className={`${styles.hero} ${className}`} {...props} />
);

Hero.defaultProps = {
  className: '',
};

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
