import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Section from '../elements/Section';
import SectionTitle from '../elements/SectionTitle';
import HomeHero from '../components/homepage/HomeHero';
import Container from '../components/core/Container';
import Card from '../components/core/Card';
import CardRow from '../components/core/CardRow';
import ObjectCard from '../components/core/ObjectCard';
import MediaCard from '../components/media/MediaCard';
import DarkButton from '../elements/DarkButton';
import InternalLink from '../elements/Link/InternalLink';
import CallToAction from '../components/core/CallToAction';
import Layout from '../components/core/Layout';
import ShopBanner from '../components/homepage/ShopBanner';

const IndexPage = ({ data }) => {
  const { media } = data.allMediaJson;
  const { articles } = data.allMarkdownRemark;
  const { objects } = data.allObjectsJson;

  return (
    <Layout>
      <HomeHero imgSizes={data.heroImage.childImageSharp.sizes} />
      <Container>
        <Section>
          <CardRow>
            {articles.map(({ article }) => {
              const {
                title, description, thumbnail, index,
              } = article.frontmatter;
              return (
                <Card
                  key={index}
                  imageSizes={!thumbnail || thumbnail.childImageSharp.sizes}
                  title={title.toString()}
                  content={description}
                  href={article.fields.slug}
                  linkText="Find out more"
                />
              );
            })}
          </CardRow>
        </Section>
        <Section>
          <SectionTitle>Explore objects in the gallery</SectionTitle>
          <CardRow>
            {objects.map(({ object }) => (
              <ObjectCard
                key={object.prn}
                object={object}
                imagePlaceholder={data.missingImageSizes}
              />
            ))}
          </CardRow>
          <InternalLink to="/collection/">
            <CallToAction as={DarkButton} text="Explore all objects" />
          </InternalLink>
        </Section>
        <Section>
          <SectionTitle>Explore video and audio</SectionTitle>
          <CardRow>
            {media.map(({ item }) => (
              <MediaCard
                key={item.media_id}
                media={item}
                missingImageSizes={data.missingImageSizes}
              />
            ))}
          </CardRow>
          <InternalLink to="/media/">
            <CallToAction as={DarkButton} text="Explore all video and audio" />
          </InternalLink>
        </Section>
      </Container>
      <ShopBanner bookImagePath={data.bookImage.publicURL} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMediaJson: PropTypes.object.isRequired,
    allMarkdownRemark: PropTypes.object.isRequired,
    allObjectsJson: PropTypes.object.isRequired,
  }).isRequired,
};

export default IndexPage;

export const CardImagesSetFragment = graphql`
  fragment CardImagesSetFragment on FileConnection {
    edges {
      node {
        name
        ...CardImageSizesFragment
      }
    }
  }
`;

export const IndexPageImages = graphql`
  query IndexPageImages {
    missingImageSizes: allFile(filter: { name: { eq: "missing-image" } }) {
      edges {
        node {
          ...CardImageSizesFragment
        }
      }
    }
    heroImage: file(relativePath: { eq: "general/hero.png" }) {
      ...HeroImageSizesFragment
    }
    bookImage: file(relativePath: { eq: "general/book.jpg" }) {
      publicURL
    }
    allObjectsJson(filter: { prn: { regex: "/^(RRM10384|RRM46664|RRM37)$/" } }) {
      ...ObjectSummaryFragment
    }
    allMediaJson(filter: { media_id: { regex: "/^(1|2|3)$/" } }) {
      ...MediaSummaryFragment
    }
    allMarkdownRemark(
      filter: { frontmatter: { card: { eq: true } } }
      sort: { fields: [frontmatter___index], order: ASC }
    ) {
      articles: edges {
        article: node {
          frontmatter {
            index
            title
            description
            thumbnail {
              ...CardImageSizesFragment
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
