import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './styles.module.css';

const HeroImage = ({ imageSizes, className, ...props }) => (
  <div className={`${styles.heroImage} ${className}`} {...props}>
    <Img sizes={imageSizes} />
  </div>
);

HeroImage.defaultProps = {
  className: '',
};

HeroImage.propTypes = {
  className: PropTypes.string,
};

export default HeroImage;
