import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const SectionTitle = ({ className, children, ...props }) => (
  <h2 className={`${styles.sectionTitle} ${className}`} {...props}>
    {children}
  </h2>
);

SectionTitle.defaultProps = {
  className: '',
};

SectionTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SectionTitle;
