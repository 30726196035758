/* eslint react/forbid-prop-types: 0 */

import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import Hero from '../Hero';
import Banner from '../Banner';
import HeroHeader from '../HeroHeader';
import HeroImage from '../HeroImage';
import Columns from '../Columns';
import Column from '../Column';
import CallToAction from '../../core/CallToAction';
import DarkButton from '../../../elements/DarkButton';
import InternalLink from '../../../elements/Link/InternalLink';
import TabLink from '../../../elements/Link/TabLink';
import albukharyLogoLight from '../../../static/images/general/albukhary-light.svg';

const HomeHero = ({ imgSizes }) => (
  <Hero>
    <HeroImage imageSizes={imgSizes} />
    <Banner>
      <HeroHeader>
        <h1>The Albukhary Foundation Gallery of the Islamic world</h1>
        <h3>Rooms 42-43</h3>
      </HeroHeader>
      <Columns>
        <Column>
          <p>
            The Albukhary Foundation Gallery of the Islamic world represents an exciting new vision,
            displayed across two magnificent refurbished galleries at the heart of the British
            Museum. The British Museum’s Islamic collection comprises a broad and diverse spectrum
            of the material culture produced from the seventh century to the present day in the
            Islamic world, a series of regions stretching from West Africa to Southeast Asia. From
            archaeological material to contemporary art, from the paintings and vessels made for
            royal patrons to the evocative objects of daily life, this new Gallery brings together
            the stories of interconnected worlds across time and geography.
          </p>
        </Column>
        <Column>
          <p>
            The British Museum’s Albukhary Foundation Gallery of the Islamic world, and this
            website, was made possible thanks to a generous donation from the Albukhary Foundation.
            We are grateful to the Trustees of the Foundation and its chairman, Syed Mokhtar
            Albukhary, and for the support of Syed Mohamed Albukhary, Director of the Islamic Arts
            Museum Malaysia.
          </p>
          <div className={styles.linksContainer}>
            <div className={styles.logo}>
              <TabLink href="http://albukharyfoundation.my/">
                <img alt="Albukhary foundation logo" src={albukharyLogoLight} />
              </TabLink>
            </div>
            <InternalLink className={styles.callToAction} to="/albukhary-foundation/">
              <CallToAction as={DarkButton} text="About the Albukhary Foundation" />
            </InternalLink>
          </div>
        </Column>
      </Columns>
    </Banner>
  </Hero>
);

HomeHero.propTypes = {
  imgSizes: PropTypes.object.isRequired,
};

export default HomeHero;

export const query = graphql`
  fragment HeroImageSizesFragment on File {
    childImageSharp {
      sizes(maxWidth: 1440) {
        ...GatsbyImageSharpSizes
      }
    }
  }
`;
