import React from 'react';
import PropTypes from 'prop-types';
import Container from '../../core/Container';
import styles from './styles.module.css';

const Banner = ({ children, className, ...props }) => (
  <div className={`${styles.banner} ${className}`} {...props}>
    <Container>{children}</Container>
  </div>
);

Banner.defaultProps = {
  className: '',
};

Banner.propTypes = {
  className: PropTypes.string,
};

export default Banner;
